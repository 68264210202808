import React from "react"
import CoachingProcessContent from "../components/coachingprocess/coachingProcessContent"
import ContactUs from "../components/common/contactUs"
import GoToFAQ from "../components/common/goToFAQ"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

const title = `Coaching-Prozess`

const CoachingProcessPage = () => (
  <Layout title={title} >
    <SEO title={title} pathname="/coaching-prozess" />
    <CoachingProcessContent />
    <GoToFAQ />
    <ContactUs />
  </Layout>
)

export default CoachingProcessPage
